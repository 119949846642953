import React, { ReactElement } from 'react';
import * as Icons from 'react-icons/lu';
import { Link } from 'react-router-dom';
import Hovered from '@/Modules/App/Components/Library/Hovered/Hovered';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { MenuItemPros } from '@/Modules/App/Components/Menu/MenuItem/MenuItem.interface';
import { MenuItemStyles } from '@/Modules/App/Components/Menu/MenuItem/MenuItem.styles';
import { withLocation } from '@/Modules/HOC/withLocation';
import { LuChevronDown } from 'react-icons/lu';
import { ApiAdminLegalNoticeService } from '@/Service/Admin/ApiAdminLegalNoticeService';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';

class MenuItem extends React.Component<MenuItemPros, { legalNoticeToValidate: number, legalNoticeNewOrders: number }>
{
	private apiAdminLegalNoticeService: ApiAdminLegalNoticeService = new ApiAdminLegalNoticeService();
	private pollingIntervalId?: number;

	constructor(props: MenuItemPros)
	{
		super(props);

		// State
		this.state = {
			legalNoticeToValidate: 0,
			legalNoticeNewOrders: 0,
		};
	}

	async componentDidMount(): Promise<void>
	{
		const numberUrlRegex = /^\/admin\/legal-notices\/\d+$/;

		if (
			this.props.urlPath === '/admin/legal-notices/validate'
			|| this.props.urlPath === '/admin/legal-notices/new-orders'
		) {
			this.pollingIntervalId = window.setInterval(() =>
			{
				this.countLegalNoticeNewOrders();
				this.countLegalNoticeToValidate();
			}, 900000);
			await this.countLegalNoticeNewOrders();
			await this.countLegalNoticeToValidate();
		}
	}

	async componentDidUpdate(prevProps: MenuItemPros): Promise<void>
	{
		if (prevProps.location.pathname !== this.props.location.pathname) {
			const numberUrlRegex = /^\/admin\/legal-notices\/\d+$/;
			if (numberUrlRegex.test(this.props.location.pathname)) {
				await this.countLegalNoticeNewOrders();
			}
		}
	}

	componentWillUnmount(): void
	{
		if (this.pollingIntervalId) {
			clearInterval(this.pollingIntervalId);
		}
	}

	render(): ReactElement
	{
		const IconComponent = Icons[this.props.iconName!];
		const isActive: boolean = this.props.location.pathname === this.props.urlPath;

		return (
			<>
				{ this.props.type === 'separatorItem' && (
					<div style={ MenuItemStyles.separatorItem }>
						<LuChevronDown size={ 14 }/>
						<span>{ this.props.label }</span>
					</div>
				) }

				{ this.props.type === 'menuItem' && (
					<Hovered<HTMLDivElement>>
						{ ({ isHovered, onMouseEnter, onMouseLeave, ref }) => (
							<Link to={ this.props.urlPath! }>
								<div
									ref={ ref }
									style={ {
										...(this.props.style || MenuItemStyles.item),
										...(isHovered ? UserConnectedService.isDeveloper() ? MenuItemStyles.devHoveredItem : MenuItemStyles.hoveredItem : {}),
									} }
									onMouseEnter={ onMouseEnter }
									onMouseLeave={ onMouseLeave }
									onClick={ this.props.onClick }
								>
									{ IconComponent && (
										<IconComponent
											fontSize={ 18 }
											color={
												isActive ? colors.blueRibbon500 : (UserConnectedService.isDeveloper()) ? colors.white : colors.gray600 }
										/>
									) }
									<span
										style={ MenuItemStyles.text(isActive, UserConnectedService.isDeveloper()) }>{ this.props.label }</span>
									{ this.props.urlPath === '/admin/legal-notices/validate' && this.state.legalNoticeToValidate > 0 &&
                    <div style={ MenuItemStyles.notification }>
											{ this.state.legalNoticeToValidate }
                    </div>
									}
									{ this.props.urlPath === '/admin/legal-notices/new-orders' && this.state.legalNoticeNewOrders > 0 &&
                    <div style={ MenuItemStyles.notification }>
											{ this.state.legalNoticeNewOrders }
                    </div>
									}
								</div>
							</Link>
						) }
					</Hovered>
				) }
			</>
		);
	}

	private async countLegalNoticeToValidate(): Promise<void>
	{
		await this.apiAdminLegalNoticeService.specificListCount('validate')
			.then((response) =>
			{
				if (response && response.totalCount !== undefined) {
					this.setState({ legalNoticeToValidate: response.totalCount });
				} else {
					this.setState({ legalNoticeToValidate: 0 });
				}
			})
		;
	}

	private async countLegalNoticeNewOrders(): Promise<void>
	{
		await this.apiAdminLegalNoticeService.specificListCount('new-orders')
			.then((response) =>
			{
				if (response && response.totalCount !== undefined) {
					this.setState({ legalNoticeNewOrders: response.totalCount });
				} else {
					this.setState({ legalNoticeNewOrders: 0 });
				}
			})
		;
	}
}

export default withLocation(MenuItem);
