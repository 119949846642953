import { CSSProperties } from 'react';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';
import { themeColors } from '@/Modules/App/Style/Base/Theme.styles';

export const MenuItemStyles = {
	container: {} as CSSProperties,

	separatorItem: {
		fontSize: 12,
		marginTop: 25,
		marginBottom: 5,
		fontWeight: 500,
		display: 'flex',
		alignItems: 'center',
		padding: '5px 12px',
		gap: 12,
		color: colors.gray400
	} as CSSProperties,

	item: {
		position: 'relative',
		padding: '6px 10px',
		display: 'flex',
		alignItems: 'center',
		color: colors.gray700,
		gap: 10,
	} as CSSProperties,
	devItem: {
		padding: '6px 10px',
		display: 'flex',
		alignItems: 'center',
		color: colors.white,
		gap: 10,
	} as CSSProperties,

	text: (isActive: boolean, isDeveloper: boolean) => ({
		...FontStyles.text,
		color: (isActive) ? colors.blueRibbon500 : (isDeveloper) ? colors.white : colors.gray600,
		fontWeight: (isActive) ? 600 : 500,
	}) as CSSProperties,

	hoveredItem: {
		backgroundColor: colors.gray200,
		color: colors.blueRibbon500,
		borderRadius: 6,
	} as CSSProperties,

	devHoveredItem: {
		backgroundColor: themeColors.success,
		color: colors.blueRibbon500,
		borderRadius: 6,
	} as CSSProperties,

	notification: {
		position: 'absolute',
		width: 13,
		height: 13,
		color: colors.white,
		borderRadius: 50,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 10,
		backgroundColor: colors.error500,
		top: 8,
		right: 0,
	} as CSSProperties,

} as const;