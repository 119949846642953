import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { SelectorCategoriesProps, SelectorCategoriesState } from './SelectorCategories.interface';
import { ApiAdminFormBuilderCategoryService } from '@/Service/Admin/ApiAdminFormBuilderCategoryService';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';
import { ApiAppService } from '@/Service/Api/ApiAppService';
import LegalNoticeTypeEnum from '@/Enum/LegalNoticeTypeEnum';

const initState: SelectorCategoriesState = {
	isLoading: true,
	categories: [],
	childCategories: [],
	selectedCategory: null,
	selectedChildCategory: null,
	isBodacc: false,
};

export class SelectorCategoriesService extends BaseComponentService<SelectorCategoriesProps, SelectorCategoriesState>
{
	private apiFormBuilderCategoryService: ApiAdminFormBuilderCategoryService | ApiAppService;

	constructor()
	{
		super({} as SelectorCategoriesProps, initState);

		// Dispatch Service
		this.apiFormBuilderCategoryService = (UserConnectedService.isAdmin() || UserConnectedService.isSuperAdmin())
			? new ApiAdminFormBuilderCategoryService()
			: new ApiAppService();

		// Bind
		this.setSelectedCategory = this.setSelectedCategory.bind(this);
		this.setChildCategoriesState = this.setChildCategoriesState.bind(this);
		this.setSelectedChildCategory = this.setSelectedChildCategory.bind(this);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		if (this.props.stateUpdater.selectedCategory) {
			await this.handleInitProcess(this.props.stateUpdater);
		} else {
			await this.setCategoriesState(this.props.categoryFilter!);
		}
	}

	/**
	 * Set Array Categories
	 */
	async setCategoriesState(categoryFilter?: LegalNoticeTypeEnum[]|null, callback?: () => void): Promise<void> {
		try {
			let categories;
			if (UserConnectedService.isAdmin() || UserConnectedService.isSuperAdmin()) {
				categories = await (this.apiFormBuilderCategoryService as ApiAdminFormBuilderCategoryService).list('alphabetical');
			} else {
				categories = await (this.apiFormBuilderCategoryService as ApiAppService).categoryList();
			}

			if (categoryFilter || this.props.isGuide) {
				categories = categories.filter((cat: any) =>
					LegalNoticeTypeEnum.filterLegalNoticeGuide.some(
						(guideCat) => guideCat.value === cat.categoryType
					) &&
					cat.label.toLowerCase() !== 'tribunal judiciaire'
					&& cat.label.toLowerCase() !== 'tribunal de commerce'
					&& cat.label.toLowerCase() !== 'acceptation de l\'actif net'
				);
			}

			this.setState({
				categories,
				isLoading: false,
			}, callback);

		} catch (error: any) {
			console.error('Error occurred on set Categories State', error);
			this.setState({ isLoading: false });
		}
	}

	/**
	 * Handle process for update data
	 * @param data
	 */
	async handleInitProcess(data: Partial<SelectorCategoriesState>): Promise<void>
	{
		let selectedCategory;
		let selectedChildCategory;

		if(UserConnectedService.isAdmin() || UserConnectedService.isSuperAdmin()) {
			selectedCategory = await (this.apiFormBuilderCategoryService as ApiAdminFormBuilderCategoryService).show(data.selectedCategory?.id!);
			selectedChildCategory = selectedCategory.childCategories.find(
				(childCategory: FormBuilderCategoryInterface) => childCategory.id === data.selectedChildCategory?.id
			);
		} else {
			selectedCategory = data.selectedCategory;
			selectedChildCategory = data.selectedChildCategory;
		}

		this.setSelectedCategory(selectedCategory);
		this.setSelectedChildCategory(selectedChildCategory);
		this.setIsBodaccState(data.isBodacc!);
		await this.setCategoriesState(null, () => this.props.callbackState(this.state));
	}

	/**
	 * Set Array child categories
	 */
	setChildCategoriesState(): void {
		let childCategories;
		if (this.props.isGuide && this.state.selectedCategory?.label === 'Constitution') {
			childCategories = this.state.selectedCategory?.childCategories.filter((childCategory) =>
				childCategory.label !== 'Constitution SA' &&
				childCategory.label !== 'Constitution SPFPL SARL' &&
				childCategory.label !== 'Constitution SPFPL SAS'
			);
		} else {
			childCategories = this.state.selectedCategory?.childCategories;
		}

		this.setState({
			childCategories
		});
	}

	/**
	 * Set Selected Category State
	 * @param category
	 */
	setSelectedCategory(category: FormBuilderCategoryInterface): void
	{
		this.setState({
			selectedCategory: category,
		}, () =>
		{
			this.setChildCategoriesState();
			if(!this.props.stateUpdater.selectedChildCategory) {
				this.setSelectedChildCategory(null);
			}
		});
	}

	/**
	 * Set Selected Child Category State
	 * @param childCategory
	 */
	setSelectedChildCategory(childCategory: FormBuilderCategoryInterface|null): void
	{
		this.setState({
			selectedChildCategory: childCategory,
		}, () => this.props.callbackState(this.state));
	}

	/**
	 * Set is Bodacc
	 * @param isBodacc
	 */
	setIsBodaccState(isBodacc: boolean): void
	{
		this.setState({
			isBodacc
		}, () => this.props.callbackState(this.state));
	}

}