import React, { Component, ReactElement } from 'react';
import { LegalNoticesProps, LegalNoticesState } from '@/Modules/LegalNotice/Common/LegalNotices.interface';
import HeroSection from '@/Modules/App/Components/HeroSection/HeroSection';
import ListTableManager from '@/Modules/App/Components/ListTableManager/ListTableManager';
import { LegalNoticesAdminService } from '@/Modules/LegalNotice/Admin/List/Regular/LegalNoticesAdmin.service';
import {
	LegalNoticesNewOrdersAdminService
} from '@/Modules/LegalNotice/Admin/List/NewOrders/LegalNoticesNewOrdersAdmin.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';

class LegalNoticesNewOrders extends Component<LegalNoticesProps, LegalNoticesState>
{
	private legalNoticesNewOrdersService = new LegalNoticesNewOrdersAdminService();
	private legalNoticesAdminService: LegalNoticesAdminService = new LegalNoticesAdminService();

	constructor(props: LegalNoticesProps)
	{
		super(props);

		// Config service
		this.legalNoticesNewOrdersService.setProps(this.props);
		this.legalNoticesNewOrdersService.subscribeState(this);

		// State
		this.state = this.legalNoticesNewOrdersService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.legalNoticesNewOrdersService.init();
	}

	componentWillUnmount(): void
	{
		this.legalNoticesNewOrdersService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		return (
			<>
				<HeroSection
					title={ <div>Nouvelles commandes</div> }
					icon={ null }
				/>

				<ListTableManager
					isTableControls={ true }
					refreshList={ this.state.legalNoticeList }
					tag={ 'legal-notice-payment' }
					tableColumns={ this.legalNoticesAdminService.buildColumnHeader() }
					serviceList={ (params) => this.legalNoticesAdminService.list({ type: 'new-orders', filterParams: params }) }
					filters={ this.legalNoticesAdminService.buildFilters() }
					onRowClick={ (event: any, item: any) => this.props.navigation('/admin/legal-notices/' + item.id) }
				/>
			</>
		);
	}
}

export default withGlobalContext(LegalNoticesNewOrders);