import { ApiAppService } from '@/Service/Api/ApiAppService';
import { ApiPublicService } from '@/Service/Api/ApiPublicService';
import { ApiAdminFormBuilderCategoryService } from '@/Service/Admin/ApiAdminFormBuilderCategoryService';
import { DepartmentInterface } from '@/Modules/LegalNotice/Interface/DepartmentInterface';
import { ApiAdminLegalNoticeService } from '@/Service/Admin/ApiAdminLegalNoticeService';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import { ApiAdminPricingService } from '@/Service/Admin/ApiAdminPricingService';
import { stripHtmlTags } from '@/Utils/StripHtmlTags';
import LocalStorageService from '@/Service/Common/LocalStorageService';
import { NewspaperInterface } from '@/Modules/LegalNotice/Interface/NewspaperInterface';

export interface priceLineInterface
{
  id: number,
  label: string,
  description: string,
  vatRate: number,
  price: number
}

export class LegalNoticePriceService
{
  formBuilderCategoryService: ApiAdminFormBuilderCategoryService;
  appService: ApiAppService;
  publicService: ApiPublicService;
  legalNoticeService: ApiAdminLegalNoticeService;
  apiPricingService: ApiAdminPricingService;
  isAdmin: boolean;

  constructor(isAdmin: boolean)
  {
    this.isAdmin = isAdmin;

    this.formBuilderCategoryService = new ApiAdminFormBuilderCategoryService();
    this.appService = new ApiAppService();
    this.publicService = new ApiPublicService();
    this.legalNoticeService = new ApiAdminLegalNoticeService();
    this.apiPricingService = new ApiAdminPricingService();
  }

  async getBodacPrice(): Promise<number>
  {
    const response = (this.isAdmin)
      ? await this.apiPricingService.search('bodacc')
      : await this.publicService.pricingLineSearch('bodacc')
    ;

    return response.price;
  }

  async getBodacPriceFull(): Promise<priceLineInterface>
  {
    return (this.isAdmin)
      ? await this.apiPricingService.search('bodacc')
      : await this.publicService.pricingLineSearch('bodacc')
    ;
  }

  async getPrintPrice(): Promise<number>
  {
    const response = (this.isAdmin)
      ? await this.apiPricingService.search('journal')
      : await this.publicService.pricingLineSearch('journal')
    ;
    return response.price;
  }

  async getPrintPriceFull(selectedNewspaper: NewspaperInterface): Promise<priceLineInterface>
  {
    const searchNewspaperByCompany = selectedNewspaper.editorial === 'heraultjuridique'
      ? 'journal'
      : 'journal - echo'
    ;

    return (this.isAdmin)
      ? await this.apiPricingService.search(searchNewspaperByCompany)
      : await this.publicService.pricingLineSearch(searchNewspaperByCompany)
    ;
  }

  async getShippingCostPrice(numberOfCopies: number): Promise<number>
  {
    const response = (this.isAdmin)
      ? await this.apiPricingService.search((numberOfCopies > 1) ? 'Frais d\'envoi multiple' : 'Frais d\'envoi unique')
      : await this.publicService.pricingLineSearch((numberOfCopies > 1) ? 'Frais d\'envoi multiple' : 'Frais d\'envoi unique')
    ;
    return response.price;
  }

  async getShippingCostPriceFull(numberOfCopies: number): Promise<priceLineInterface>
  {
    return (this.isAdmin)
      ? await this.apiPricingService.search((numberOfCopies > 1) ? 'Frais d\'envoi multiple' : 'Frais d\'envoi unique')
      : await this.publicService.pricingLineSearch((numberOfCopies > 1) ? 'Frais d\'envoi multiple' : 'Frais d\'envoi unique')
    ;
  }

  async getExternPrice(): Promise<number>
  {
    const response = (this.isAdmin)
      ? await this.apiPricingService.search('Frais de débours et transmission')
      : await this.publicService.pricingLineSearch('Frais de débours et transmission')
    ;
    return response.price;
  }

  async getExternPriceFull(): Promise<priceLineInterface>
  {
    return (this.isAdmin)
      ? await this.apiPricingService.search('Frais de débours et transmission')
      : await this.publicService.pricingLineSearch('Frais de débours et transmission')
      ;
  }

  getAnnexPrice(
    selectedDepartment: DepartmentInterface,
    selectedCategory: FormBuilderCategoryInterface,
    isFixedPrice: boolean
  ): number
  {
    if (isFixedPrice) {
      const pricingCategory = selectedDepartment.pricingAnnexFixedPrice.pricingCategories.find(
        (pc: any) => pc.formBuilderCategory.id === selectedCategory.id
      );
      return pricingCategory ? pricingCategory.price : 0;
    } else {
      return selectedDepartment.pricingAnnexCharacterPrice.price;
    }
  }

  isFixedPrice(selectedDepartment: any, selectedCategory: any): boolean
  {
    if (!selectedDepartment || !selectedCategory) return false;

    const pricingCategory = selectedDepartment?.pricingAnnexFixedPrice?.pricingCategories?.find(
      (pc: any) => pc.formBuilderCategory?.id === selectedCategory?.id
    );

    return !!(pricingCategory);
  }

  async nbCharacters(selectedDepartment: any, selectedCategory: any, legalNotice: any, headerCharacterCount: number): Promise<number>
  {
    const isFixedPrice: boolean = this.isFixedPrice(
      selectedDepartment,
      selectedCategory
    );

    let totalCharacters = stripHtmlTags(legalNotice.content).length +
      stripHtmlTags(legalNotice.title).length +
      stripHtmlTags(legalNotice.signature).length;

    if (legalNotice.option.isHeader) {
      totalCharacters += this.countTempHeaderContent(legalNotice.consumer) || 0;
    }

    return isFixedPrice ? 1 : totalCharacters;
  }

  countTempHeaderContent(consumerData: any): number
  {
    const headerContent = `
    ${consumerData.name}
    ${consumerData.legalStatus}
    au capital de ${consumerData.capital}
    siège social: ${consumerData.address.zipCode} ${consumerData.address.city}
    ${consumerData.address.number}, ${consumerData.address.street}
    ${consumerData.siren} RCS ${consumerData.rcs}
  `;

    const strippedHeaderContent = headerContent
      .trim()
      .replace(/\s+/g, ' ')
      .replace(/, /g, ',')
      .replace(/: /g, ':')
      .replace(/\s{2,}/g, ' ')
    ;

    return strippedHeaderContent.length;
  }
}