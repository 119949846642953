import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import { LegalNoticesProps, LegalNoticesState } from '@/Modules/LegalNotice/Common/LegalNotices.interface';
import { BaseLegalNoticesService } from '@/Modules/LegalNotice/Common/BaseLegalNotices.service';

export class LegalNoticesNewOrdersAdminService extends BaseComponentService<LegalNoticesProps, LegalNoticesState>
{
	constructor()
	{
		super({} as LegalNoticesProps, BaseLegalNoticesService.baseInitState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
	}
}