import React, { ReactElement } from 'react';
import { NewspaperTypeEnum } from '@/Enum/NewspaperTypeEnum';
import { DepartmentInterface } from '@/Modules/LegalNotice/Interface/DepartmentInterface';
import { NewspaperInterface } from '@/Modules/LegalNotice/Interface/NewspaperInterface';
import FormLabel from '@/Modules/App/Components/Atom/Form/FormLabel';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Checkbox from '@/Modules/App/Components/Atom/Form/Checkbox';
import { addDays, format, getDay, isAfter, setDay, setHours, setMinutes, subWeeks } from 'date-fns';
import Input from '@/Modules/App/Components/Library/Input/Input';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { BsInfoCircle } from 'react-icons/bs';
import { dateFormatFull } from '@/Utils/DateUtils';
import { BlockConfigStyle } from '@/Modules/LegalNotice/Style/BlockConfigStyle';
import { AuthContextType } from '@/Provider/Interface/Auth/AuthContextType';
import Select from '@/Modules/App/Components/Library/Select/Select';
import SectionCollapsed from '@/Modules/App/Components/Sections/SectionCollapsed/SectionCollapsed';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { LuInfo } from 'react-icons/lu';
import { Alert } from 'react-bootstrap';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';

interface ComponentProps
{
	isDisplayBlock: boolean,
	isDisabled?: boolean,
	authContext: AuthContextType,
	// List
	departmentList: DepartmentInterface[],
	newspaperList: NewspaperInterface[],
	newspaperListRequest: (department: DepartmentInterface, newspaperType: NewspaperTypeEnum) => Promise<void>
	// NewspaperType
	selectedNewspaperType: NewspaperTypeEnum | string,
	onSelectedNewspaperType: (event: any) => void,
	// Department
	selectedDepartment: DepartmentInterface | null,
	onSelectedDepartment: (department: DepartmentInterface) => void,
	// Newspaper
	selectedNewspaper: NewspaperInterface | null,
	onSelectedNewspaper: (event: any) => void,
	// Publish Date
	publishDate: Date | null,
	onChangePublishDate: (date: Date | null) => void,
	isForcePublishDate: boolean,
	onForcePublishDate: (date: Date | null, isForcePublishDate: boolean) => void,
	// Number Of Copies
	numberOfCopies: number,
	onChangeNumberOfCopies: (event: any) => void,
	// Reference
	reference: string | null,
	onChangeReference: (event: any) => void
	isInputRefNeeded?: boolean,
	isAdmin: boolean,
}

interface ComponentState
{
	isDisabled: boolean,
	publishDays: number[],
	publishDate: Date | null,
	isForcePublishDate: boolean,
	closureDate: Date | null,
	isLoading: boolean
}

export default class BlockConfigComponent extends React.Component<ComponentProps, ComponentState>
{
	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();
	}

	render(): ReactElement
	{
		const propsNewspaperType =
			typeof this.props.selectedNewspaperType === 'string'
				? NewspaperTypeEnum.findByValue(this.props.selectedNewspaperType)
				: this.props.selectedNewspaperType;

		const formatDepartmentList = this.props.departmentList.map((department) => ({
			...department,
			displayName: `${ department.name } (${ department.code })`,
		}));

		return (
			<>
				{ this.props.isDisplayBlock && (
					<>
						<SectionCollapsed
							title="Configuration de l’annonce"
							isCollapsed={ false }
							highlightColor={ colors.gray100 }
						>
							{ this.state.isLoading ? (
								<LoaderComponent/>
							) : (
								<>
									<Select
										label="Support du journal"
										textHelp="Correspond au choix du support de publication de votre annonce légale"
										listOptions={ NewspaperTypeEnum.options }
										displayKey="label"
										value={ NewspaperTypeEnum.findByValue(this.props.selectedNewspaperType).label || NewspaperTypeEnum.WEB.label }
										onSelect={ this.onSelectedNewspaperType.bind(this) }
										isRequired
										isDisabled={ this.props.isDisabled }
									/>

									<div
										style={ {
											display: 'grid',
											gridTemplateColumns: 'repeat(2, 1fr)',
											gap: 20,
											marginTop: 15,
										} }
									>
										<Select
											label="Choix du département"
											listOptions={ formatDepartmentList }
											displayKey="displayName"
											onSelect={ this.props.onSelectedDepartment }
											value={
												this.props.selectedDepartment
													? `${ this.props.selectedDepartment.name } (${ this.props.selectedDepartment.code })`
													: ''
											}
											isSearchNeeded
											isRequired
											isDisabled={ this.props.isDisabled }
										/>

										{ this.props.selectedDepartment && (
											<Select
												key={
													this.props.selectedDepartment?.id +
													this.props.selectedNewspaper?.name!
												}
												label="Choix du journal"
												listOptions={ this.props.newspaperList }
												displayKey="name"
												value={ this.props.selectedNewspaper?.name! }
												onSelect={ (event: any) =>
													this.props.onSelectedNewspaper(event)
												}
												isDisabled={
													this.props.isDisabled || !this.props.selectedDepartment
												}
												isRequired
											/>
										) }
									</div>

									{ this.props.isDisabled && (
										<Alert
											key="info"
											variant="warning"
											style={ {
												marginTop: 20,
												padding: 10,
												borderRadius: 10,
												backgroundColor: CssVariableEnum['--color-yellow-100'],
											} }
										>
											<div
												style={ {
													display: 'grid',
													gridTemplateColumns: '20px auto',
													gap: 5,
													alignItems: 'flex-start',
												} }
											>
												<LuInfo
													style={ {
														fontSize: 18,
														color: CssVariableEnum['--color-yellow-500'],
														textAlign: 'center',
													} }
												/>
												<p
													style={ {
														fontSize: 12,
														fontWeight: 500,
														color: CssVariableEnum['--color-yellow-600'],
													} }
												>
													Note : Annonce légale en mode "modification", pour
													changer de département / journal, merci d'annuler et de
													dupliquer l'annonce.
												</p>
											</div>
										</Alert>
									) }

									{ this.props.selectedNewspaper && (
										<>
											<div
												style={ {
													display: 'flex',
													gap: 30,
													marginTop: '10px',
												} }
											>
												<div>
													<FormLabel content="Date de publication"/>
													<DatePicker
														showIcon
														className="form-control"
														selected={ this.state.publishDate }
														onChange={ (date) =>
															this.onChangePublishDate(date)
														}
														filterDate={ this.isPublishDay.bind(this) }
														dateFormat="dd/MM/yyyy"
														minDate={ new Date() }
													/>
												</div>

												{ propsNewspaperType?.value ===
													NewspaperTypeEnum.PAPER.value &&
													this.props.isAdmin && (
														<div style={ { marginTop: '30px' } }>
															<Checkbox
																label="Forcer la date de publication ?"
																name="force-publish-date"
																isChecked={ this.state.isForcePublishDate }
																options={ { rowRightCheckbox: true } }
																onCheckedChange={ (event: any) =>
																	this.onChangeIsForcePublishDate(event)
																}
															/>
														</div>
													) }
											</div>
										</>
									) }

								{ propsNewspaperType?.value === NewspaperTypeEnum.PAPER.value &&
									<>
										<div style={ { marginTop: '10px' } }>
											<Input
												type="number"
												label="Nombre de Journaux"
												name="numberOfCopies"
												min={ "0" }
												placeholder={ 'Renseignez le nombre de Journaux (justificatif(s))' }
												value={ this.props.numberOfCopies || '' }
												onChange={ (event: any) => this.props.onChangeNumberOfCopies(parseInt(event.target.value)) }
											/>
										</div>
									</>
								}

								{ this.state.publishDate &&
									this.props.selectedNewspaper &&
									this.renderClosureDay() }

								{ this.props.isInputRefNeeded && (
									<div style={ { marginTop: '10px' } }>
										<Input
											type="text"
											width="100%"
											label="Référence dossier"
											name="reference"
											value={ this.props.reference || '' }
											onChange={ (event: any) =>
												this.props.onChangeReference(event.target.value)
											}
										/>
									</div>
								) }
								</>
							) }
						</SectionCollapsed>
					</>
				) }
			</>
		);
	}

	//<editor-fold desc="CreateOld (state, didMount, ...) methods" defaultstate="collapsed">

	componentDidMount(): void
	{
		this.handleBuildPublishDate();
		this.setState({ isLoading: false });
	}

	componentDidUpdate(prevProps: ComponentProps): void
	{
		if (prevProps.selectedDepartment !== this.props.selectedDepartment && this.props.selectedDepartment !== null) {
			this.setState({ isDisabled: !this.props.selectedDepartment });
		}

		if (prevProps.selectedNewspaper !== this.props.selectedNewspaper) {
			this.setState({ publishDate: null, closureDate: null }, () =>
			{
				this.handleBuildPublishDate();
			});
		}
	}

	private initState(): ComponentState
	{
		return {
			isDisabled: false,
			publishDays: [],
			publishDate: (this.props.publishDate) ? this.props.publishDate : null,
			isForcePublishDate: this.props.isForcePublishDate,
			closureDate: null,
			isLoading: true,
		};
	}

	//</editor-fold>

	//<editor-fold desc="Render methods" defaultstate="collapsed">

	private renderClosureDay(): ReactElement
	{
		const formattedClosureDate: string = this.state.closureDate ? format(this.state.closureDate, 'PPpp') : '';
		const isOutdated: boolean = !!(this.state.closureDate && isAfter(new Date(), this.state.closureDate));

		return (
			<>
				{ !this.props.isForcePublishDate &&
          <div style={ BlockConfigStyle.closureDateContainerStyle(isOutdated) }>
            <div style={ BlockConfigStyle.closureDateBodyStyle(isOutdated) }>
              <BsInfoCircle
                color={ (isOutdated) ? CssVariableEnum['--color-error-500'] : CssVariableEnum['--color-blue-500'] }/>
            </div>
            <span style={ BlockConfigStyle.closureDateSpanStyle(isOutdated) }>
              {
								(isOutdated)
									? 'Date et heure de bouclage dépassé'
									: <>
										Date et heure de bouclage limite avant le &nbsp;
										<span style={ { fontWeight: 600, textDecoration: 'underline', } }>
											{ dateFormatFull(formattedClosureDate) }
										</span>
									</>
							}
            </span>
          </div>
				}
			</>
		);
	}

	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private onSelectedNewspaperType(publishType: any): void
	{
		this.props.onSelectedNewspaperType(publishType);

		// Check if a department is selected
		if (this.props.selectedDepartment) {
			this.props.newspaperListRequest(this.props.selectedDepartment, publishType);
		}
	}

	private handleBuildPublishDate(): void
	{
		if (this.props.selectedNewspaper) {
			this.buildPublishDateList(this.props.selectedNewspaper.newspaperPublishDays);

			const currentPublishDate = this.state.publishDate || this.props.publishDate;

			if (currentPublishDate) {
				const publishDayInfo = this.findPublishDayInfo(currentPublishDate);
				const closureDate = publishDayInfo ? this.calculatedClosureDate(currentPublishDate, publishDayInfo) : null;

				this.setState({
					publishDate: currentPublishDate,
					closureDate: closureDate,
				});
			} else {
				let nextPublishDate = this.getNextPublishDate(this.props.selectedNewspaper.newspaperPublishDays);
				const publishDayInfo = this.findPublishDayInfo(nextPublishDate);
				const closureDate = publishDayInfo ? this.calculatedClosureDate(nextPublishDate, publishDayInfo) : null;

				this.setState({
					publishDate: nextPublishDate,
					closureDate: closureDate,
				});

				this.props.onChangePublishDate(nextPublishDate);
			}
		}
	}

	private onChangePublishDate(date: Date | null): void
	{
		if (!date) return;

		const publishDayInfo = this.findPublishDayInfo(date);
		if (!publishDayInfo) return;

		const closureDate = this.calculatedClosureDate(date, publishDayInfo);

		this.setState({ publishDate: date, closureDate: closureDate });
		this.props.onChangePublishDate(date);
	}

	private onChangeIsForcePublishDate(event: any): void
	{
		const isChecked = event.target.checked;

		if (isChecked) {
			const forcedPublishDate = this.getNextPublishDateForForce(this.props.selectedNewspaper?.newspaperPublishDays || []);

			this.setState({
				isForcePublishDate: true,
				publishDate: forcedPublishDate,
				closureDate: null,
			});

			this.props.onForcePublishDate(forcedPublishDate, true);
		} else {
			const nextPublishDate = this.getNextPublishDate(this.props.selectedNewspaper?.newspaperPublishDays || []);
			const publishDayInfo = this.findPublishDayInfo(nextPublishDate);
			const closureDate = this.calculatedClosureDate(nextPublishDate, publishDayInfo);

			this.setState({
				isForcePublishDate: false,
				publishDate: nextPublishDate,
				closureDate: closureDate,
			});

			this.props.onForcePublishDate(nextPublishDate, false);
		}
	}

	private isPublishDay(date: Date): boolean
	{
		const dayOfWeek: number = date.getDay();
		return this.state.publishDays.includes(dayOfWeek as never);
	}

	private buildPublishDateList(publishDays: any[]): void
	{
		const publishDayList: number[] = publishDays.map((dayObj: any) => this.mapDayStrToDayInt(dayObj.day));
		this.setState({ publishDays: publishDayList });
	}

	private mapDayStrToDayInt(day: string): number
	{
		const mapDayToInt: { [key: string]: number } = {
			'Sunday': 0,
			'Monday': 1,
			'Tuesday': 2,
			'Wednesday': 3,
			'Thursday': 4,
			'Friday': 5,
			'Saturday': 6
		};

		return mapDayToInt[day] ?? 0;
	}

	private getNextPublishDate(publishDays: any[]): Date
	{
		const today = new Date();
		const dayOfWeek = getDay(today);

		const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

		const sortedPublishDays = publishDays
			.map(day => ({
				...day,
				dayIndex: daysOfWeek.indexOf(day.day),
			}))
			.sort((a, b) => a.dayIndex - b.dayIndex);

		let nextPublishDate: Date | null = null;

		for (const publishDay of sortedPublishDays) {
			const publishDayIndex = publishDay.dayIndex;
			let candidateDate = setDay(today, publishDayIndex, { weekStartsOn: 0 });

			if (publishDayIndex < dayOfWeek) {
				candidateDate = addDays(candidateDate, 7);
			}

			const publishDayInfo = this.findPublishDayInfo(candidateDate);
			const closureDate = publishDayInfo ? this.calculatedClosureDate(candidateDate, publishDayInfo) : null;
			const isOutdated = !!(closureDate && isAfter(today, closureDate));

			if (!isOutdated) {
				nextPublishDate = candidateDate;
				break;
			}
		}

		if (!nextPublishDate) {
			const firstPublishDayIndex = sortedPublishDays[0].dayIndex;
			nextPublishDate = addDays(setDay(today, firstPublishDayIndex, { weekStartsOn: 0 }), 7);
		}

		return nextPublishDate;
	}

	private findPublishDayInfo(date: Date): any | undefined
	{
		const dayOfWeek = format(date, 'EEEE');
		return this.props.selectedNewspaper && this.props.selectedNewspaper.newspaperPublishDays.find(
			(dayObj: any) => dayObj.day === dayOfWeek
		);
	}

	private calculatedClosureDate(selectedDate: any, publishDateInfo: any): Date | null
	{
		if (!publishDateInfo || !publishDateInfo.closureDay) return null;

		const closureDayInfo = publishDateInfo.closureDay;
		const closureDayOfWeek = this.mapDayStrToDayInt(closureDayInfo.day);

		let closureDate = setDay(selectedDate, closureDayOfWeek, { weekStartsOn: 1 });

		if (closureDate > selectedDate) {
			closureDate = subWeeks(closureDate, 1);
		}

		closureDate = setHours(closureDate, parseInt(closureDayInfo.hours));
		closureDate = setMinutes(closureDate, parseInt(closureDayInfo.minute));

		return closureDate;
	}

	private getNextPublishDateForForce(publishDays: any[]): Date
	{
		const today = new Date();
		const dayOfWeek = getDay(today);
		const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

		const sortedPublishDays = publishDays
			.map(day => ({
				...day,
				dayIndex: daysOfWeek.indexOf(day.day)
			}))
			.sort((first, second) => first.dayIndex - second.dayIndex);

		for (let i = 0; i < sortedPublishDays.length; i++) {
			const candidateDayIndex = sortedPublishDays[i].dayIndex;

			if (candidateDayIndex === dayOfWeek) {
				const nextIndex = (i + 1) % sortedPublishDays.length;
				return addDays(setDay(today, sortedPublishDays[nextIndex].dayIndex), (nextIndex <= i ? 7 : 0));
			}

			if (candidateDayIndex > dayOfWeek) {
				return setDay(today, candidateDayIndex);
			}
		}

		const firstPublishDayIndex = sortedPublishDays[0].dayIndex;
		return addDays(setDay(today, firstPublishDayIndex), 7);
	}


	//</editor-fold>
}