import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';

export class ApiAdminLegalNoticeFileService extends BaseService
{
  legalNoticeFileUrl: string = `${ BaseService.API_URL }/v1/admin/legal-notice-files`;

  async getFiles(legalNoticeId: number): Promise<any>
  {
    try {
      const response: Response = await fetch(
        `${ this.legalNoticeFileUrl }/${legalNoticeId}`,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  //<editor-fold desc="Estimate file methods" defaultstate="collapsed">

  async createEstimateFile(legalNoticeId: number, legalNoticeData: any): Promise<any>
  {
    try {
      const response: Response = await fetch(
        `${ this.legalNoticeFileUrl }/estimate/${legalNoticeId}/true`,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(legalNoticeData),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async getFileStatus(legalNoticeId: number, documentName: string): Promise<any>
  {
    try {
      const response: Response = await fetch(
        `${ this.legalNoticeFileUrl }/${legalNoticeId}/${documentName}/status`,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async editEstimateFile(legalNoticeId: number, legalNoticeData: any): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.legalNoticeFileUrl }/estimate/${legalNoticeId}/false`,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(legalNoticeData),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  //</editor-fold>

  //<editor-fold desc="Billing file methods" defaultstate="collapsed">

  async createBillingFile(legalNoticeId: number, legalNoticeData: any): Promise<any>
  {
    try {
      const response: Response = await fetch(
        `${ this.legalNoticeFileUrl }/billing/${legalNoticeId}/true`,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(legalNoticeData),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async editBillingFile(legalNoticeId: number, legalNoticeData: any): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.legalNoticeFileUrl }/billing/${legalNoticeId}/false`,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(legalNoticeData),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  //</editor-fold>

  //<editor-fold desc="Credit note file methods" defaultstate="collapsed">

  async createCreditNoteFile(legalNoticeId: number, legalNoticeData: any): Promise<any>
  {
    try {
      const response: Response = await fetch(
        `${ this.legalNoticeFileUrl }/credit-note/${legalNoticeId}/true`,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(legalNoticeData),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async editCreditNoteFile(legalNoticeId: number, legalNoticeData: any): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.legalNoticeFileUrl }/credit-note/${legalNoticeId}/false`,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(legalNoticeData),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  //</editor-fold>

  //<editor-fold desc="Certificate file methods" defaultstate="collapsed">

  async createCertificateFile(legalNoticeId: number, legalNoticeData: any): Promise<any>
  {
    try {
      const response: Response = await fetch(
        `${ this.legalNoticeFileUrl }/certificate/${legalNoticeId}/true`,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(legalNoticeData),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async editCertificateFile(legalNoticeId: number, legalNoticeData: any, urlParams: string | null = null): Promise<any>
  {
    const fullUrl = `${this.legalNoticeFileUrl}/certificate/${legalNoticeId}/false${urlParams ? urlParams : ''}`;

    try {
      const response = await fetch(
        fullUrl,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(legalNoticeData),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async editCertificateWebFile(legalNoticeId: number): Promise<any>
  {
    try {
      const response = await fetch(
        `${ this.legalNoticeFileUrl }/certificate-web/${legalNoticeId}`,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  //</editor-fold>
}