import React, { Component, ReactElement } from 'react';
import { DynamicGuideFormProps, DynamicGuideFormState } from './DynamicGuideForm.interface';
import { DynamicGuideFormService } from './DynamicGuideForm.service';
import { withGlobalContext } from '@/Context/Global/Global.context.wrapper';
import { CardStyles } from '@/Modules/App/Style/Components/Card.styles';
import { ClipLoader } from 'react-spinners';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import FormSection from '@/Modules/LegalNotice/Components/Form/FormSection/FormSection';
import FormSubSection from '@/Modules/LegalNotice/Components/Form/FormSubSection/FormSubSection';
import LegalNoticeTypeEnum from '@/Enum/LegalNoticeTypeEnum';
import SirenConsumerAutoFill from '@/Modules/LegalNotice/Components/Form/SirenConsumerAutoFill/SirenConsumerAutoFill';
import Input from '@/Modules/App/Components/Library/Input/Input';

class DynamicGuideForm extends Component<DynamicGuideFormProps, DynamicGuideFormState>
{
	private dynamicGuideFormService = new DynamicGuideFormService();

	constructor(props: DynamicGuideFormProps)
	{
		super(props);

		// Config service
		this.dynamicGuideFormService.setProps(this.props);
		this.dynamicGuideFormService.subscribeState(this);

		// State
		this.state = this.dynamicGuideFormService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.dynamicGuideFormService.init();
	}

	componentDidUpdate(prevProps: DynamicGuideFormProps) : void
	{
		if (prevProps.childCategory !== this.props.childCategory) {
			this.dynamicGuideFormService.setLoading(true);
			setTimeout(() => {
				this.dynamicGuideFormService.handleInit(this.props.childCategory);
			}, 500);
		}
	}

	componentWillUnmount(): void
	{
		this.dynamicGuideFormService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const { formSections, isLoading } = this.state;

		if (isLoading) {
			return (
				<div style={ {
					...CardStyles.simpleCardGrayContainer,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				} }>
					<ClipLoader
						color={ colors.gray400 }
						loading={ isLoading }
						size={ 25 }
						aria-label="Loading Spinner"
						data-testid="loader"
					/>
				</div>
			);
		}

		const showInput = !formSections.some(section => section.id === 11 || section.id === 45);

		return (
			<>
				<div style={ CardStyles.simpleCardGrayContainer }>

					{ showInput && (
						<div style={{ marginTop: 10 }}>
							<Input
								type="text"
								label="Affaire"
								textHelp={"Merci de renseigner le nom de l'affaire de cette annonce légale (Dénomination, nom de personne physique ou moral)"}
								name="consumer-name-additional"
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									event.preventDefault();
									this.dynamicGuideFormService.setConsumerState({ name: event.target.value });
								}}
								value={ this.state.consumer?.name || '' }
								required={ true }
							/>
						</div>
					)}

					{ !LegalNoticeTypeEnum.filterWithoutSiren.some(category => String(category.value) === this.props?.childCategory?.categoryType!) && (
						<SirenConsumerAutoFill
							sirenValue={ this.state.consumer?.siren! }
							errorMessage={ null }
							autoFillButton={ !showInput }
							callbackState={ (state) => this.dynamicGuideFormService.setConsumerState({ siren: state.siren }) }
							callbackResponseApi={ (consumerData) =>
								this.dynamicGuideFormService.handleConsumerContentFromSiren(consumerData)
							}
						/>
					) }

					{ formSections.map((section, index) => (
						section.sectionParentTypeOption ? (
							<FormSubSection
								key={ index }
								formSection={ section }
								signature={ this.state.signature! }
								stateUpdater={{
									subSectionSelected: section.subSections.find((subsection) =>
										this.state.formContents.some((input) => input.subSectionId === subsection.id)
									)
								}}
								inputContents={ this.state.formContents }
								selectedSubSection={ (subSection) => this.dynamicGuideFormService.setFormSubSectionsState(subSection) }
								removeSubSectionContent={ (subSection) => this.dynamicGuideFormService.removeSubSectionContent(subSection.parentId!) }
								duplicateSubSection={ (subSection) => this.dynamicGuideFormService.duplicateSubSection(subSection) }
								removeDuplicatedSubSection={ (duplicatedGroup: number) => this.dynamicGuideFormService.removeDuplicatedSubSection(duplicatedGroup)}
								handleSectionErrors={ this.state.sectionErrors }
								onChange={ (value, inputSectionId, duplicateGroupKey?: string) => this.dynamicGuideFormService.handleChangeContent(value, inputSectionId, duplicateGroupKey) }
							/>
						) : (
							<FormSection
								key={ index }
								formSection={ section }
								signature={ this.state.signature! }
								inputContents={ this.state.formContents }
								onChange={ (value, inputSectionId) => this.dynamicGuideFormService.handleChangeContent(value, inputSectionId) }
							/>
						)
					)) }
				</div>
			</>
		);
	}
}

export default withGlobalContext(DynamicGuideForm);