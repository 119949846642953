import React, { Component, ReactElement } from 'react';
import { FormSectionProps, FormSectionState } from './FormSection.interface';
import { FormSectionService } from './FormSection.service';
import { CardStyles } from '@/Modules/App/Style/Components/Card.styles';
import { ClipLoader } from 'react-spinners';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import Input from '@/Modules/App/Components/Library/Input/Input';
import { FormBuilderInputTypeEnum } from '@/Enum/FormBuilderInputTypeEnum';
import { InputInterface } from '@/Modules/App/Components/Library/Input/Input.interface';
import { FormBuilderInputsInterface } from '@/Modules/FormBuilder/Interface/FormBuilderInputsInterface';
import Select from '@/Modules/App/Components/Library/Select/Select';
import { FormSectionStyles } from '@/Modules/LegalNotice/Components/Form/FormSection/FormSection.styles';
import Textarea from '@/Modules/App/Components/Atom/Form/Textarea';
import Checkbox from '@/Modules/App/Components/Atom/Form/Checkbox';
import {
	FormContentInterface
} from '@/Modules/LegalNotice/Components/Form/DynamicGuideForm/DynamicGuideForm.interface';

class FormSection extends Component<FormSectionProps, FormSectionState>
{
	private formSectionService = new FormSectionService();

	constructor(props: FormSectionProps)
	{
		super(props);

		// Config service
		this.formSectionService.setProps(this.props);
		this.formSectionService.subscribeState(this);

		// State
		this.state = this.formSectionService.getState();
	}

	//<editor-fold desc="Effects methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.formSectionService.init();
	}

	componentWillUnmount(): void
	{
		this.formSectionService.unsubscribe();
	}

	//</editor-fold>

	render(): ReactElement
	{
		const inputTypeMapping: { [key in FormBuilderInputTypeEnum]: InputInterface } = {
			[FormBuilderInputTypeEnum.TEXT]: 'text',
			[FormBuilderInputTypeEnum.TEXTAREA]: 'text',
			[FormBuilderInputTypeEnum.NUMBER]: 'number',
			[FormBuilderInputTypeEnum.SWITCH]: 'checkbox',
			[FormBuilderInputTypeEnum.SELECTOR]: 'select',
			[FormBuilderInputTypeEnum.CHECKBOX]: 'checkbox',
			[FormBuilderInputTypeEnum.DATE]: 'date',
			[FormBuilderInputTypeEnum.RADIO]: 'checkbox',
		};

		const { formSection, isLoading } = this.state;

		if (isLoading) {
			return (
				<div style={ {
					...CardStyles.simpleCardGrayContainer,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				} }>
					<ClipLoader
						color={ colors.gray400 }
						loading={ isLoading }
						size={ 25 }
						aria-label="Loading Spinner"
						data-testid="loader"
					/>
				</div>
			);
		}

		return (
			<div style={ FormSectionStyles.container }>
				{ this.state.titleNeeded &&
          <div style={ (this.props.titleStyle) ? this.props.titleStyle : FormSectionStyles.title }>
						{ formSection.title }
          </div>
				}
				{ formSection.formBuilderInputs.map((inputSection: FormBuilderInputsInterface, index: number) => (
					<React.Fragment key={ index }>
						{ inputSection.type === FormBuilderInputTypeEnum.SELECTOR ? (
							<Select
								label={ inputSection.label }
								listOptions={ inputSection.formBuilderOptions! }
								isRequired={ inputSection.isRequired! }
								onSelect={ (value) => this.props.onChange(value.label, inputSection.id as number, (this.props.duplicateId) ?? this.props.duplicateId )
								}
								value={ this.props.inputContents.find(input => input.id === inputSection.id)?.value || '' }
								errorMessage={ this.props.inputContents.find(
									input => input.id === inputSection.id)?.error || ''
								}
							/>
						) : inputSection.type === FormBuilderInputTypeEnum.TEXTAREA ? (
							<Textarea
								label={ inputSection.label }
								name={ `textarea-section-${ inputSection.type }-${ inputSection.id }${ this.props.duplicateId ? '-' + this.props.duplicateId : '' }` }
								required={ inputSection.isRequired }
								value={ this.props.inputContents.find(input => input.id === inputSection.id)?.value || '' }
								isError={ Boolean(this.props.inputContents.find(input => inputSection.id === input.id)?.error) }
								onChange={ (event: React.ChangeEvent<HTMLTextAreaElement>) => {
									event.preventDefault();
									this.props.onChange(event.target.value, inputSection.id as number, (this.props.duplicateId) ?? this.props.duplicateId);
								} }
							/>
						) : inputSection.type === FormBuilderInputTypeEnum.CHECKBOX ? (
							<Checkbox
								label={ inputSection.label! }
								name={ `${ inputSection.label }-${ index }` }
								options={ { rowLeftCheckbox: true } }
								isChecked={ this.props.inputContents.find((input: FormContentInterface) => inputSection.id === input.id)?.value === 'true' }
								onCheckedChange={ () => {
									const currentValue = this.props.inputContents.find(input => inputSection.id === input.id)?.value;
									const newValue = currentValue === 'true' ? 'false' : 'true';
									this.props.onChange(newValue, inputSection.id as number);
								} }
							/>
						) : (
							<Input
								type={ inputTypeMapping[inputSection.type] }
								label={ inputSection.label }
								name={
									`input-${ inputSection.type }-${ inputSection.id }${ this.props.duplicateId ? '-' + this.props.duplicateId : '' }`
								}
								textHelp={ inputSection.textHelp! }
								errorMessage={
									this.props.duplicateId
										? this.props.inputContents.find(
										input =>
											input.inputLabel === inputSection.label &&
											input.duplicatedGroup === Number(this.props.duplicateId)
									)?.error || ''
										: this.props.inputContents.find(
										input => input.id === inputSection.id
									)?.error || ''
								}
								required={ inputSection.isRequired }
								value={
									inputSection.label && inputSection.label.toLowerCase() === 'signature'
										? this.props.signature
										: this.props.duplicateId
											? this.props.inputContents.find(
											input =>
												input.inputLabel === inputSection.label &&
												input.duplicatedGroup === Number(this.props.duplicateId)
										)?.value || ''
											: this.props.inputContents.find(input => input.id === inputSection.id)?.value || ''
								}
								onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
									event.preventDefault();
									this.props.onChange(event.target.value, Number(inputSection.id), (this.props.duplicateId) ?? this.props.duplicateId );
								} }
							/>
						) }
					</React.Fragment>
				)) }
			</div>
		);

	}
}

export default FormSection;