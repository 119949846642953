import { BaseComponentService } from '@/Modules/App/Services/Common/BaseComponentService';
import {
	ContextSwitcherProps,
	ContextSwitcherState
} from '@/Modules/App/Components/ContextSwitcher/ContextSwitcher.interface';
import { ApiAdminCompanyService } from '@/Service/Admin/ApiAdminCompanyService';
import { ApiClientService } from '@/Service/Api/ApiClientService';
import { UserConnectedService } from '@/Modules/App/Services/User/UserConnectedService';
import LocalStorageService from '@/Service/Common/LocalStorageService';

const initState: ContextSwitcherState = {
	isLoading: false,
	errorMessage: null,
	contextName: null,
	contextList: [],
};

export class ContextSwitcherService extends BaseComponentService<ContextSwitcherProps, ContextSwitcherState>
{
	private apiContextService: ApiAdminCompanyService | ApiClientService | undefined;

	constructor()
	{
		super({} as ContextSwitcherProps, initState);
	}

	/**
	 * Initialize the service by setting the context list and name
	 * @return Promise<void>
	 */
	async init(): Promise<void>
	{
		try {
			this.setState({ isLoading: true });
			this.setContext();
		} catch (error) {
			console.error('Erreur lors de l\'initialisation du service ContextSwitcher:', error);
			this.setState({ errorMessage: 'Une erreur est survenue lors de l\'initialisation.' });
		} finally {
			this.setState({ isLoading: false });
		}
	}

	/**
	 * Set the name of the current context (company or client) based on user role
	 * @param contextName
	 * @return Promise<void>
	 */
	setContextName(contextName: string): void
	{
			// Super Sellsy
			if (UserConnectedService.isSuperAdmin()) {
				this.setState({ contextName });
			}
			// Sellsy
			else if (UserConnectedService.isAdmin()) {
				this.setState({ contextName });
			}
	}

	/**
	 * Switch context (company or client) and redirect to admin page
	 * @param contextId The ID of the context to switch to
	 * @param contextName
	 * @return Promise<void>
	 */
	async switchContext(contextId: number, contextName: string): Promise<void>
	{
		try {
			this.setState({ isLoading: true });
			// Super Sellsy Role
			if (UserConnectedService.isSuperAdmin()) {
				await this.props.authCompanyContext?.refreshCompany(contextId, true);
				this.setContextName(contextName);
				this.props.navigation('/admin');

				// Reload page here ! Avoid error ! Don't remove the this
				window.location.href = '/admin';
			}
			// Sellsy Role
			else if (UserConnectedService.isAdmin()) {
				await this.props.authClientContext?.refreshClient(contextId);
				this.props.navigation('/admin');
			}
		} catch (error) {
			console.error('Error while switching context:', error);
		} finally {
			setTimeout(() => {
				this.setState({ isLoading: false });
			}, 2000);
		}
	}

	/**
	 * Determine the correct service to use (ApiAdminCompanyService or ApiClientService) based on user role	 * @private
	 * @return ApiAdminCompanyService | ApiClientService | undefined
	 */
	private async setContext(): Promise<void>
	{
		// Role Super Sellsy
		if (UserConnectedService.isSuperAdmin()) {
			this.initSuperAdmin();
		}
		// Role Sellsy
		else if (UserConnectedService.isAdmin()) {
			this.initAdmin();
		}

		await this.setContextList();
	}

	private async initSuperAdmin(): Promise<void>
	{
		this.apiContextService = new ApiAdminCompanyService();

		const currentContext = await this.apiContextService?.show(Number(LocalStorageService.getAuthCompanyId()));
		this.setContextName(currentContext.name);
	}

	private async initAdmin(): Promise<void>
	{
		this.apiContextService = new ApiAdminCompanyService();

		const currentContext = await this.apiContextService?.show(Number(LocalStorageService.getAuthClient()));
		this.setContextName(currentContext.name);
	}

	/**
	 * Fetch and set the list of available contexts
	 */
	async setContextList(): Promise<void>
	{
		try {
			this.setState({ isLoading: true });
			const response = await this.apiContextService?.list();

			if (response && Array.isArray(response)) {
				const contextList: any[] = [];
				response.forEach((element: any) => contextList.push(element));
				this.setState({ contextList });
			} else {
				console.error('Erreur: Réponse non valide ou vide');
			}
		} catch (error) {
			console.error('Erreur lors de la récupération de la liste des contextes:', error);
		} finally {
			this.setState({ isLoading: false });
		}
	}
}