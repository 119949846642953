import React, { Component, ReactElement } from 'react';
import {
	LoaderSwitchContextProps, LoaderSwitchContextState
} from '@/Modules/App/Components/Loader/LoaderSwitchContext/LoaderSwitchContext.interface';
import {
	LoaderSwitchContextStyles
} from '@/Modules/App/Components/Loader/LoaderSwitchContext/LoaderSwitchContext.styles';
import { BounceLoader, ClipLoader } from 'react-spinners';
import { colors } from '@/Modules/App/Style/Variables/Colors.styles';
import { FontStyles } from '@/Modules/App/Style/Base/Font.styles';

class LoaderSwitchContext extends Component<LoaderSwitchContextProps, LoaderSwitchContextState>
{
	render(): ReactElement
	{
		return (
			<div style={ LoaderSwitchContextStyles.wrapper }>
				<div style={ LoaderSwitchContextStyles.container }>
					<ClipLoader
						color={ colors.blueRibbon500 }
						loading={ true }
						size={ 30 }
						cssOverride={{ borderWidth: 3 }}
						aria-label="Loading Spinner"
						data-testid="loader"
					/>
					<div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
						<div style={{ textAlign: 'center', fontWeight: 500}}>
							{ this.props.textLoading }
						</div>
						{ this.props.textSecondary &&
              <div style={{ textAlign: 'center', ...FontStyles.textHelp}}>
								{ this.props.textSecondary }
              </div>
						}
					</div>
				</div>
			</div>
		);
	}

}

export default LoaderSwitchContext;